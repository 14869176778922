import { Navigate } from "react-router-dom";

function ProtectedRoute({ allowedRoles, element }) {

    const user = JSON.parse(localStorage.getItem('userData'));
    const roles = user.roles.map((item) => { return item.name });

    if (roles.some(role => allowedRoles.includes(role))) {
        return element;
    } else {
        return <Navigate to="/not-authorized" replace />;
    }
}

export default ProtectedRoute;