import React from 'react';
import ReactDOM from 'react-dom/client';
import "./Services/i18n";
import App from './Engine/App';
import reportWebVitals from './Engine/reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode> 
);

reportWebVitals();
