export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

export const getUserLanguages = () => JSON.parse(localStorage.getItem('Languages'));

export const getLanguage = () => (localStorage.getItem('i18nextLng'));

export const getHomeRouteForLoggedInUser = userRole => {
  //console.log(userRole);
  if (userRole === 'receptionist') return '/apps/deals'
  if (userRole === 'rooms-servant') return '/apps/deals'
  if (userRole === 'housekeeping') return '/apps/requests'
}