import logo from "../../../Resources/snafi_icons_27.png";
import "./loading.scss";

const Component = () => {
  return (
    <section id="Loading">
      <div className={`loader`}>
        <img className="logo" src={logo} alt="Hotellom" />
      </div>
    </section>
  );
};

export default Component;
