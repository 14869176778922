import { useTranslation } from 'react-i18next';
import "./notauthorized.scss";
import { getHomeRouteForLoggedInUser, getUserData } from '../../../Services/utils';
import AMui from "../../Components/aMui/aMui";


const NotAuthorized = () => {

    const [t] = useTranslation();
    const userData = getUserData();

    return (
        <section id="NotAuthorized">
            <div className="container">
                <h1>401</h1>
                <h4>{t('NotAuthorized.Title')}! 🔐 </h4>
                <AMui className={'btn success outline dashed-n px-5 mt-3'} href={getHomeRouteForLoggedInUser(userData?.roles[0]?.name)} value={'Home Page'} />
                <p>
                    {t('NotAuthorized.p1')}
                    <br />
                    {t('NotAuthorized.p2')}
                </p>
            </div>
        </section>
    )
}

export default NotAuthorized;