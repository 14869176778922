import "./footer.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { BsBell, BsBellFill } from 'react-icons/bs'
import logo from "../../../Resources/logo2.svg";
import logo2 from "../../../Resources/logoWhite.png";
import { Icon } from "@iconify/react";
import ProtectedButtons from "../../Routes/Private/ProtectedButtons";

const Footer = () => {

    const history = useNavigate();
    const handleNavigate = (route) => {
        history(route);
    }
    const location = useLocation();

    const isActive = (paths) => {
        return paths.some((path) => location.pathname.startsWith(path));
    };

    return (
        <footer className="footer  grid-container" >

            {/* Deal Button */}
            <ProtectedButtons
                allowedRoles={['receptionist', 'rooms-servant']}
                element={<a
                    className={`${isActive(["/apps/deals"]) ? 'active' : ''}`}
                    onClick={() => handleNavigate("/apps/deals")}
                >
                    {isActive(["/apps/deals"]) ? <Icon icon="mdi:percent-box" className="icons" /> : <Icon icon="mdi:percent-box-outline" className="icons" />}
                </a>}
            />

            {/* Order Button */}
            <ProtectedButtons
                allowedRoles={['receptionist', 'rooms-servant']}
                element={<a
                    className={`${isActive(["/apps/orders"]) ? 'active' : ''}`}
                    onClick={() => handleNavigate("/apps/orders")}
                >
                    {isActive(["/apps/orders"]) ? <Icon icon="fluent:service-bell-24-filled" className="icons" /> : <Icon icon="fluent:service-bell-24-regular" className="icons" />}
                </a>}
            />

            {/* <ProtectedButtons
                allowedRoles={['receptionist', 'receptionist']}
                element={<div className="logobrand">
                    <a
                        className='logo'
                        onClick={() => handleNavigate("/apps/deals")}>
                        {isActive(["/apps/deals"]) ? <img src={logo} alt="Hotellom" /> : <img src={logo2} alt="Hotellom" />}
                    </a>
                </div>}
            /> */}

            {/* Messages Button */}
            <ProtectedButtons
                allowedRoles={['receptionist', 'housekeeping']}
                element={<a
                    className={`${isActive(["/apps/messages"]) ? 'active' : ''}`}
                    onClick={() => handleNavigate("/apps/messages")}>
                    {isActive(["/apps/messages"]) ? <BsBellFill size={20} /> : <BsBell size={20} />}
                </a>}
            />

            {/* Requests Button */}
            <ProtectedButtons
                allowedRoles={['receptionist', 'housekeeping']}
                element={<a
                    className={`${isActive(["/apps/requests"]) ? 'active' : ''}`}
                    onClick={() => handleNavigate("/apps/requests")}>
                    {isActive(["/apps/requests"]) ? <Icon icon="fluent:branch-request-16-filled" className="icons" /> : <Icon icon="fluent:branch-request-16-regular" className="icons" />}
                </a>}
            />

        </footer>
    )
}


export default Footer;