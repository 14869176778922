function ProtectedButtons({ allowedRoles, element }) {
    const user = JSON.parse(localStorage.getItem('userData'));
    const roles = user.roles.map((item) => item.name);

    if (roles.some(role => allowedRoles.includes(role))) {
        return element;
    } else {
        return null;
    }
}

export default ProtectedButtons;
