import { FaUser } from "react-icons/fa";
import { FiCornerUpLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import logo from "../../../Resources/Snafi logo 1.png";
import {
  getHomeRouteForLoggedInUser,
  getUserData,
} from "../../../Services/utils";
import "./navbar.scss";

const Navbar = ({ back }) => {
  const history = useNavigate();
  const userData = getUserData();

  return (
    <>
      <section id="Navbar">
        <header className="header fixed-top">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              {back ? (
                <button
                  onClick={() => {
                    history(
                      getHomeRouteForLoggedInUser(userData?.roles[0]?.name)
                    );
                  }}
                  className="btn btn-back px-2"
                >
                  <FiCornerUpLeft size={26} color={"#183018"} />
                </button>
              ) : (
                <div className="hotel-img" />
              )}
              <img className="logo" src={logo} alt="Hotellom" />
              <div
                className="user"
                onClick={() => {
                  history("/apps/profile");
                }}
              >
                <FaUser color={"#D5D5D5"} size={18} />
              </div>
            </div>
          </div>
        </header>
      </section>
    </>
  );
};

export default Navbar;
