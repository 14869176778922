import Button from "../../Components/Button/Button";
import { getHomeRouteForLoggedInUser } from "../../../Services/utils";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "./login.scss";
import SnafiLogo from "../../../Resources/Snafi logo 2.png";

const Login = () => {
  const [loginInput, setloginInput] = useState({
    email: "",
    password: "",
  });

  const [errors, SetErrors] = useState({
    password: "",
    email: "",
  });

  const handleInput = (e) => {
    setloginInput({ ...loginInput, [e.target.name]: e.target.value });
  };

  const LoginSubmit = (e) => {
    e.preventDefault();
    SetErrors("");

    const formData = new FormData();
    formData.append("email", loginInput.email);
    formData.append("password", loginInput.password);

    fetch(`https://api.hotellom.com/loginStandar`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((res) => {
        localStorage.setItem("auth_token", res.data.token);
        localStorage.setItem("userData", JSON.stringify(res.data.user));

        // using window location just to ensure the notification status refreched to true
        window.location.href = getHomeRouteForLoggedInUser(
          res.data.user.roles[0].name
        );
      })
      .catch((error) => {
        toast.warning("Unauthorised");
      });
  };

  return (
    <section id="Login">
      <div className="content"></div>
      <div className="container mt-3">
        <div className="row">
          <div className="box col-sm-8 col-lg-6 col-xl-4 text-center">
            <form onSubmit={LoginSubmit} className="py-5 px-4">
              <div className="header">
                <div className="logo">
                  <img src={SnafiLogo} className="img-fluid" alt="" />
                </div>
                <p className="title">Manager</p>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  name="email"
                  onChange={handleInput}
                  value={loginInput.email}
                  className="form-control"
                  placeholder="name@example.com"
                />
                <label>Email address</label>
                <div className="error-area">
                  {errors.email ? errors.email : ""}
                </div>
              </div>

              <div className="form-floating">
                <input
                  type="password"
                  name="password"
                  onChange={handleInput}
                  value={loginInput.password}
                  className="form-control"
                  placeholder="Password"
                />
                <label>Password</label>
                <div className="error-area">
                  {errors.password ? errors.password : ""}
                </div>
              </div>
              <Button
                type="submit"
                className="light w-100 mb-3 mt-4"
                value={"Sign in"}
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
