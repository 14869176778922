import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../../Services/utils";
import "./profile.scss";
import axios from "axios";


const Profile = () => {
    const history = useNavigate()
    const userData = getUserData();

    const logoutSubmit = (e) => {

        e.preventDefault();

        axios.post(`/logoutStandar`).then(response => {
            if (response.data.success === true) {
                localStorage.clear();
                window.location.href = '/login';
            }
        });
    }

    return (
        <section className="profile" >
            <div className="container" >
                <div className="row" >
                    <div className="col-md-6 mx-auto text-center" >
                        <div className="user mx-auto mb-3" >
                            <FaUser color={"#D5D5D5"} size={50} />
                        </div>
                        <h3 className="mb-5 text-center" >{`${userData.firstname} ${userData.lastname}`}</h3>
                        <button onClick={() => history('/apps/edit/profile')} className="btn mx-auto mb-3">Edit profile</button>
                        <button className="btn mx-auto mb-3">Contact Us</button>
                        <button className="btn mx-auto" onClick={logoutSubmit}>Logout</button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Profile;