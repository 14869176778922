import React, { useState, useEffect, useRef } from "react";
import { BiPause, BiPlay } from "react-icons/bi";
import WaveSurfer from "wavesurfer.js";
import "./audioWavePlayer.scss";

const Waveform = ({ item, isSender }) => {
  const [playing, setPlaying] = useState(false);
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);

  useEffect(() => {
    wavesurferRef.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: isSender ? "#D3AC67" : "#183018",
      progressColor: isSender ? "#F5E9D4" : "#183018",
      cursorColor: "transparent",
      barWidth: 3,
      barRadius: 3,
      barGap: 2,
      barMinHeight: 1,
      responsive: true,
      height: 60,
      backend: "WebAudio",
    });

    wavesurferRef.current.load(item.content);

    wavesurferRef.current.on("finish", () => {
      setPlaying(false);
    });

    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, [item.content, isSender]);

  const handlePlay = () => {
    if (playing) {
      wavesurferRef.current.pause();
      setPlaying(false);
    } else {
      if (window.currentWaveSurfer) {
        window.currentWaveSurfer.pause();
      }
      wavesurferRef.current.play();
      setPlaying(true);
      window.currentWaveSurfer = wavesurferRef.current;
    }
  };

  return (
    <div className="audio-container">
      <div
        className={`button ${isSender ? "sender" : ""}`}
        onClick={handlePlay}
      >
        {playing ? (
          <BiPause color={isSender ? "#F5E9D4" : "#183018"} size={27} />
        ) : (
          <BiPlay color={isSender ? "#F5E9D4" : "#183018"} size={27} />
        )}
      </div>
      <audio id={`track_${item.id}`} src={item.content} />
      <div ref={waveformRef} id={`waveform_${item.id}`} className="waveform" />
    </div>
  );
};

export default Waveform;
