import { useEffect, useRef, useState } from "react";
import "./conversation.scss";
import { RiSendPlaneFill } from "react-icons/ri";
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import { useParams } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import { useMemo } from "react";
import AudioWavePlayer from "../../Components/AudioWavePlayer/AudioWavePlayer";
import Pusher from "pusher-js";
import { toast } from "react-toastify";

const Messages = () => {
  const [loading, SetLoading] = useState(true);
  const [data, setData] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { id, room_number, room_id, user_id } = useParams();
  const [content, setcontent] = useState("");
  const Language = localStorage.getItem("i18nextLng");
  const messagesRef = useRef(null);
  const audio = new Audio("https://api.hotellom.com/audio/bell.mp3");

  const reversedData = useMemo(() => {
    return [...data].reverse();
  }, [data]);

  const handleMessageRespone = () => {
    axios
      .get(`messages/byConversation/${id}`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    axios
      .get(`messages/byConversation/${id}`)
      .then((response) => {
        if (loading) {
          setData(response.data.data);
        }
        SetLoading(false);
      })
      .catch((error) => console.log(error));

    const pusher = new Pusher("ca58059b7d1eb7c94fbd", {
      cluster: "eu",
    });

    const channel = pusher.subscribe(
      "sendManagerNotif-channel" + userData.hotel.id
    );
    channel.bind("sendManagerNotif-event" + userData.hotel.id, (data) => {
      handleMessageRespone();
    });
    return () => {
      channel.unbind("sendManagerNotif-event" + userData.hotel.id);
      pusher.unsubscribe("sendManagerNotif-channel" + userData.hotel.id);
    };
  }, []);

  useEffect(() => {
    // Scroll to the last message when component mounts or when reversedData changes
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [reversedData]);

  const handleAddMessage = (e, content) => {
    e.preventDefault();

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const formdata = new FormData();
    formdata.append("content", content);
    formdata.append("room_id", room_id);
    formdata.append("hotel_id", userData.hotel.id);
    formdata.append("user_id", user_id);
    formdata.append("fromManager", "1");
    formdata.append("conversation_id", id);
    formdata.append("type", "TEXT");

    axios
      .post(`/messages`, formdata, config)
      .then((res) => {
        setcontent("");
        handleMessageRespone();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar back />
      <section id="Conversation">
        <div className="header">
          <div className="title">
            <p>Messages</p>
          </div>
          <div className="details">
            <p className="room-number">Room Number #{room_number}</p>
          </div>
        </div>

        <div className="conversation">
          <div className="messages" ref={messagesRef}>
            {reversedData?.map((item, index) => {
              if (item.fromManager == 1) {
                return (
                  <div className="sender-message" key={index.toString()}>
                    <div
                      className={`message-content ${
                        item.demmand?.icon ? "is-demmand" : ""
                      }`}
                    >
                      <div className="logo">
                        {item.demmand?.icon && (
                          <img className="img" src={item.demmand.icon} />
                        )}
                      </div>
                      <div className="message">
                        {item.demmand && (
                          <p className="mb-0">
                            <b>
                              {Language == "en"
                                ? item.demmand.name
                                : Language == "fr"
                                ? item.demmand.name_fr
                                : item.demmand.name_sp}
                            </b>
                          </p>
                        )}
                        {item.type === "AUDIO" ? (
                          <AudioWavePlayer item={item} />
                        ) : (
                          <p>{item.content}</p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="received-message" key={index.toString()}>
                    <div
                      className={`message-content ${
                        item.demmand?.icon ? "is-demmand" : ""
                      }`}
                    >
                      <div className="logo">
                        {item.demmand?.icon && (
                          <img className="img" src={item.demmand.icon} />
                        )}
                      </div>
                      <div className="message">
                        {item.demmand && (
                          <p className="mb-0">
                            <b>
                              {Language == "en"
                                ? item.demmand.name
                                : Language == "fr"
                                ? item.demmand.name_fr
                                : item.demmand.name_sp}
                            </b>
                          </p>
                        )}
                        {item.type === "AUDIO" ? (
                          <AudioWavePlayer item={item} isSender />
                        ) : (
                          <p>{item.content}</p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="chat-action">
            <div className="form-group me-2">
              <input
                value={content}
                onChange={(e) => setcontent(e.target.value)}
                placeholder="Write Something"
                name="content"
                type="text"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAddMessage(e, content);
                  }
                }}
                className="form-control"
              />
              <button
                onClick={(e) => handleAddMessage(e, content)}
                type="submit"
                className={`btn btn-send`}
              >
                <RiSendPlaneFill size={20} color="#  " />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Messages;
