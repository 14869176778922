import { toast } from "react-toastify";
import { useEffect } from "react";
import Pusher from 'pusher-js';
import { Howl } from 'howler';
import addNotification from "react-push-notification";

const NotificationSV = ({ user }) => {

    const subscribeToChannel = (pusher, channelName, eventName, onDataCallback, cleanupVariable) => {
        const channel = pusher.subscribe(channelName);
        channel.bind(eventName, onDataCallback);

        cleanupVariable = () => {
            channel.unbind(eventName);
            pusher.unsubscribe(channelName);
        };
    };

    useEffect(() => {

        console.log("Notifications Status : ", user ? "Active" : "Inactive");
        
        const roles = user?.roles.map((item) => item.name);

        const pusher = new Pusher('ca58059b7d1eb7c94fbd', {
            cluster: 'eu',
        });

        let DealsCleanup;
        let OrderCleanup;
        let MessageCleanup;
        let RequestCleanup;

        const sound = new Howl({
            src: ['https://api.hotellom.com/audio/bell.mp3'],
            autoplay: false,
            loop: false,
        });

        const playSound = () => {
            sound.play();
        };


        const PushNotification = (message) => {
            if ('serviceWorker' in navigator && 'PushManager' in window) {
                navigator.serviceWorker.ready.then((registration) => {
                    registration.showNotification('Hotellom', {
                        body: message,
                        icon: 'https://api.hotellom.com//img/types/RESTAURANT_61d2ef048bef3.png',
                        // add other options as needed
                    });
                });
            } else {
                // Fallback for browsers that do not support service workers
                addNotification({
                    title: 'Hotellom',
                    message: message,
                    duration: 9000,
                    theme: 'darkblue',
                    icon: 'https://api.hotellom.com//img/types/RESTAURANT_61d2ef048bef3.png',
                    native: true,
                    onClick: () => window.location = "/apps/deals",
                });
            }
        };

        // Deals Channel
        if (roles?.includes("admin") || roles?.includes("receptionist") || roles?.includes("rooms-servant") || roles?.includes("housekeeping")) {
            subscribeToChannel(
                pusher,
                'CommandOffer-channel' + user?.hotel.id,
                'CommandOffer-event' + user?.hotel.id,
                (data) => {
                    if(document.hidden) {
                        PushNotification('New Offer Command Room #' + data.room)
                    }
                    toast.success('New Offer Command Room #' + data.room);
                    playSound();
                },
                DealsCleanup
            );
        }

        // Orders Channel
        if (roles?.includes("admin") || roles?.includes("receptionist") || roles?.includes("rooms-servant")) {
            subscribeToChannel(
                pusher,
                'Command-channel' + user?.hotel.id,
                'Command-event' + user?.hotel.id,
                (data) => {
                    if(document.hidden) {
                        PushNotification('New Command Room #' + data.room.room_number)
                    }
                    toast.success('New Command Room #' + data.room.room_number);
                    playSound();
                },
                OrderCleanup
            );
        }

        // Message & Conversation Channel
        if (roles?.includes("admin") || roles?.includes("receptionist")) {
            subscribeToChannel(
                pusher,
                'sendManagerNotif-channel' + user?.hotel.id,
                'sendManagerNotif-event' + user?.hotel.id,
                (data) => {
                    if(document.hidden) {
                        PushNotification('New Message From ' + data.message.user.name + ': ' + data.message.content)
                    }
                    toast.success('New Message From ' + data.message.user.name + ': ' + data.message.content);
                    playSound();
                },
                MessageCleanup
            );
        }

        // Request Channel
        if (roles?.includes("admin") || roles?.includes("receptionist") || roles?.includes("housekeeping")) {
            subscribeToChannel(
                pusher,
                'demmandUsersNotif-channel' + user?.hotel.id,
                'demmandUsersNotif-event' + user?.hotel.id,
                (data) => {
                    if(document.hidden) {
                        PushNotification('New Request')
                    }
                    toast.success('New Request');
                    playSound();
                },
                RequestCleanup
            );
        }

        // Cleanup function for all channels
        return () => {
            DealsCleanup && DealsCleanup();
            OrderCleanup && OrderCleanup();
            MessageCleanup && MessageCleanup();
            RequestCleanup && RequestCleanup();
        };
    }, [user]);

    return null;
};

export default NotificationSV;
