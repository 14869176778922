import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './button.scss';


const Button = ({ className, value, onClick, type, data_bs_toggle, data_bs_target, icon }) => {

    return (

        <div id='StyledButton'>
            <button className={'btn ' + className} type={type} id='Button' onClick={onClick} data-bs-toggle={data_bs_toggle} data-bs-target={data_bs_target}>
                {icon ? <FontAwesomeIcon icon={icon} className="icons" /> : ''}
                {value}
            </button>
        </div>

    )
}

export default Button;

