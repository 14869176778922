import { Icon } from '@iconify/react';
import './amui.scss';

const aMui = ({ className, value, href, icon, style }) => {

    return (

        <div id='Styled_aMui'>
            <a href={href} className={'btn ' + className} id='LinkMui' style={style} >
                {value}
                {icon ? <Icon icon={icon} className="icons" /> : ''}
            </a>
        </div>

    )
}

export default aMui;

