import MasterLayout from "../../Layout/Masterlayout";
import { React } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";

function PrivateRoute() {

    axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
        if (err.response.status === 401) {
            window.location.href = '/login';
        }
        if (err.response.status === 422) {
            return Promise.reject(err);
        }
        return new Promise(() => { });
    });

    return localStorage.getItem('auth_token') ? (<MasterLayout />) : (<Navigate to="/login" replace />)
}

export default PrivateRoute;



