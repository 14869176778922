import Routeslink from "../apps/Routes/public/routes";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import NotificationSV from "../apps/Components/Notifications/Notifications";
import { Notifications } from 'react-push-notification';
import { useEffect } from "react";

axios.defaults.baseURL = process.env.REACT_APP_HOTELLOM_APP_API_ENDPOINT;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Accept'] = 'application/json';


axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
})


axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  if (err.response.status === 401) {
    window.location.href = '/login';
  }
  if (err.response.status === 422) {
    return Promise.reject(err);
  }
  return new Promise(() => { });
});


function App() {

  const user = JSON.parse(localStorage.getItem('userData'))

  useEffect(() => {
    // Check if the browser supports notifications
    if ("Notification" in window) {
      // Check if the user has denied notifications
      if (Notification.permission !== "granted") {
        // If not, ask for permission
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            // Permission granted, you can now show notifications
            console.log("Notification permission granted");
          } else {
            // Permission denied
            console.log("Notification permission denied");
          }
        });
      }
    }
  }, []);
 

  return (
    <div className="App">
      <Notifications />
      <NotificationSV user={user}/>
      <Routeslink />
      <ToastContainer />
    </div>
  );

}

export default App;
