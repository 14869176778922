import { Route, Routes, Navigate } from "react-router-dom"
import Deals from "../../Pages/Deals/Deals";
import Messages from "../../Pages/Messages/Messages";
import Requests from "../../Pages/Requests/Requests";
import Login from "../../Auth/Login/Login";
import ProtectedRoute from "../Private/ProtectedRoute";
import NotFound from "../../Components/NotFound/NotFound";
import NotAuthorized from "../../Components/NotAuthorized/NotAuthorized";
import PrivateRoute from "../Private/PrivateRoute";
import Conversation from "../../Pages/Coversation/Conversation";
import Orders from "../../Pages/Orders/Orders";
import Profile from "../../Pages/Profile/Profile";
import EditProfile from "../../Pages/Profile/EditProfile/EditProfile";


const Routeslink = () => {
    return (
        <Routes>

            {/* Login Route */}
            <Route path="login"
                element={
                    localStorage.getItem('auth_token') ?
                        (<Navigate to="/apps/deals" replace />) :
                        (<Login />)
                }
            />

            <Route path="/"
                element={
                    localStorage.getItem('auth_token') ?
                        (<Navigate to="/apps/deals" replace />) :
                        (<Login />)
                }
            />

            {/* NotFound Route */}
            <Route path='*'
                element={
                    <NotFound />
                }
            />

            {/* NotFound Route */}
            <Route path='/not-found'
                element={
                    <NotFound />
                }
            />

            {/* NotAuthorized Route */}
            <Route path='/not-authorized'
                element={
                    <NotAuthorized />
                }
            />

            {/* Apps Route =>  Protected and Private Routes only */}
            <Route path="/apps" element={<PrivateRoute />}>

                <Route path="profile" element={
                    <ProtectedRoute allowedRoles={['receptionist', 'rooms-servant', 'housekeeping']} element={<Profile />} />
                } />

                <Route path="edit/profile" element={
                    <ProtectedRoute allowedRoles={['receptionist', 'rooms-servant', 'housekeeping']} element={<EditProfile />} />
                } />

                <Route path="deals" element={
                    <ProtectedRoute allowedRoles={['receptionist', 'rooms-servant']} element={<Deals />} />
                } />

                <Route path="orders" element={
                    <ProtectedRoute allowedRoles={['receptionist', 'rooms-servant']} element={<Orders />} />
                } />

                <Route path="messages" element={
                    <ProtectedRoute allowedRoles={['receptionist', 'housekeeping']} element={<Messages />} />
                } />

                <Route path="messages/conversation/:id/:room_number/:room_id/:user_id" element={
                    <ProtectedRoute allowedRoles={['receptionist', 'rooms-servant', 'housekeeping']} element={<Conversation />} />
                } />

                <Route path="requests" element={
                    <ProtectedRoute allowedRoles={['receptionist', 'housekeeping']} element={<Requests />} />
                } />

                {/*not found route*/}
                <Route path="" element={
                    <Navigate to="/not-found" replace />
                } />

            </Route>
        </Routes>
    );
}

export default Routeslink;