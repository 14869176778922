import Navbar from "../Components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import "./masterlayout.scss";
import Footer from "../Components/Footer/Footer";

const MasterLayout = () => {

    return (

        <>
            <section id="Masterlayout">
                <div className="main-container">
                    <Navbar />
                    <Footer />
                    <div className="content">
                        <Outlet />
                    </div>
                </div>
            </section>

        </>
    )
}

export default MasterLayout;