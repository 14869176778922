import { getHomeRouteForLoggedInUser, getUserData } from '../../../Services/utils';
import { useTranslation } from 'react-i18next';
import AMui from "../../Components/aMui/aMui";
import './notfound.scss';


const NotFound = () => {

    const [t] = useTranslation();
    const userData = getUserData();

    if (userData === null) {
        window.location.href = '/login';
        return null;
    }

    return (
        <section id="NotFound">
            <div className="container">
                <h1>404</h1>
                <h4>{t('NotFound.Title')} {`:(`} </h4>
                <AMui className={'btn success outline dashed-n px-5 mt-3'} href={getHomeRouteForLoggedInUser(userData?.roles[0]?.name)} value={'Home Page'} />
                <p>{t('NotFound.p')}</p>
            </div>
        </section>
    )
}

export default NotFound;