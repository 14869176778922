import languageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEN from "../apps/lang/en.json";
import translationFR from "../apps/lang/fr.json";
import translationES from "../apps/lang/es.json";
import i18n from "i18next";

const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    },
    es: {
        translation: translationES
    }
};

const languageKey = "i18nextLng";

const storedLanguage = localStorage.getItem(languageKey);

i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en", // Set a fallback language in case detection fails
        detection: {
            order: ["localStorage", "navigator"], // Check localStorage first, then use navigator language
            caches: ["localStorage"], // Cache the language in localStorage
            lookupLocalStorage: languageKey,
        },
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        }
    })
    .then(() => {
        if (!storedLanguage) {
            const detectedLanguage = i18n.language.split('-')[0];
            localStorage.setItem(languageKey, detectedLanguage);
        }
    });

export default i18n;
