import "./dealsModal.scss";
import logo from "../../../Resources/logo.png";
import axios from "axios";

const DealsModal = ({ item, onOfferSubmit }) => {
  const SubmitOffer = (e, id) => {
    e.preventDefault();

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .put(`/commandOffers/${id}`, { orderStatus: "delivered" }, config)
      .then((res) => {
        onOfferSubmit();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className="custom-modal modal fade"
      id={`E${item.id}`}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-image">
            <img
              src={item.offer.image}
              style={{ width: "100%", height: "100%", borderRadius: "12px" }}
              alt=""
            />
          </div>
          <div className="modal-info">
            <div className="modal-name">
              <p>{item.offer.titre}</p>
            </div>
            <div className="modal-prices">
              <p className="modal-old-price">{item.offer.prix} MAD</p>
              <p className="modal-new-price">{item.offer.prixFinal} MAD</p>
            </div>
          </div>
          <div className="modal-description">{item.offer.description}</div>
          {item.orderStatus !== "delivered" ? (
            <div className="modal-confirm-btn">
              <button
                className="confirm-btn btn"
                data-bs-dismiss="modal"
                onClick={(e) => SubmitOffer(e, item.id)}
              >
                {" "}
                Done{" "}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default DealsModal;
