import { useEffect, useState } from "react";
import "./messages.scss";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FaUser } from "react-icons/fa";
import { BsChat } from 'react-icons/bs';
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import { Link } from "react-router-dom";
import Pusher from 'pusher-js';
import { toast } from "react-toastify";
import audiowavepng from "../../../Resources/audio wave.png";

const Messages = () => {

    const [value, setValue] = useState(0);
    const [loading, SetLoading] = useState(true);
    const [data, setData] = useState([]);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const audio = new Audio('https://api.hotellom.com/audio/bell.mp3');


    const handleMessageRespone = () => {
        axios
            .get(`conversations`)
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => console.log(error));
    };

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {

        axios.get(`conversations`).then(response => {
            if (loading) {
                setData(response.data.data);
            }
            SetLoading(false);
        }).catch(error => console.log(error))


        const pusher = new Pusher('ca58059b7d1eb7c94fbd', {
            cluster: 'eu',
        });
        const channel = pusher.subscribe('sendManagerNotif-channel' + userData.hotel.id);
        channel.bind('sendManagerNotif-event' + userData.hotel.id, (data) => {
            handleMessageRespone();
        });
        return () => {
            channel.unbind('sendManagerNotif-event' + userData.hotel.id);
            pusher.unsubscribe('sendManagerNotif-channel' + userData.hotel.id);
        };

    }, [])

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <section id="Messages">
                <div className="header">
                    <div className="title">
                        <p>Messages</p>
                    </div>
                    <Tabs value={value} onChange={handleChange} centered className="tabs" indicatorColor="none" >
                        <Tab label="All" className="tab" />
                        <Tab label="Unread" className="tab" />
                    </Tabs>
                </div>

                <div>
                    {value === 0 &&
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    data.map(item => {
                                        return (
                                            <Link to={`/apps/messages/conversation/${item.id}/${item.room?.room_number}/${item.room?.id}/${item.user?.id}`} className={`item ${item.status === 1 ? 'read' : 'unread'}`} key={item.id}>
                                                <div className="logo">
                                                    <div className="image">
                                                        <FaUser color={"#D5D5D5"} size={25} />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <p className="room-number">Room Number #{item.room?.room_number}</p>
                                                    <p className="last-message">{item.lastMsg?.type === "AUDIO" ? <img src={audiowavepng} alt="" /> : item.lastMsg?.content  }</p>
                                                </div>
                                                <div className="details">
                                                    <button className="details-btn btn">
                                                        <BsChat size={40} className="icons" />
                                                    </button>
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                    {value === 1 &&
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    data.map(item => {
                                        return item.status === 0 ? <Link to={`/apps/messages/conversation/${item.id}/${item.room?.room_number}/${item.room?.id}/${item.user?.id}`} className={`item ${item.status === 1 ? 'read' : 'unread'}`} key={item.id}>
                                            <div className="logo">
                                                <div className="image">
                                                    <FaUser color={"#D5D5D5"} size={25} />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <p className="room-number">Room Number #{item.room?.room_number}</p>
                                                <p className="last-message">{item.lastMsg?.content}</p>
                                            </div>
                                            <div className="details">
                                                <button className="details-btn btn">
                                                    <BsChat size={40} className="icons" />
                                                </button>
                                            </div>
                                        </Link> : ''
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            </section>
        </>
    )
}

export default Messages;