import { useEffect, useState } from "react";
import "./orders.scss";
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import Pusher from 'pusher-js';
import { toast } from "react-toastify";
import { BsChat } from "react-icons/bs";
import { FaConciergeBell } from "react-icons/fa";
import { FaArrowUpWideShort } from "react-icons/fa6";
import moment from 'moment';
import { Link } from "react-router-dom";
import ProtectedButtons from "../../Routes/Private/ProtectedButtons";

const Orders = () => {

    const [loading, SetLoading] = useState(true);
    const [data, setData] = useState([]);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const audio = new Audio('https://api.hotellom.com/audio/bell.mp3');



    const handleOrderResponse = () => {
        axios
            .get(`getHotelCommands`)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {

        axios.get(`getHotelCommands`).then(response => {
            if (loading) {
                setData(response.data);
            }
            SetLoading(false);
        }).catch(error => console.log(error))


        const pusher = new Pusher('ca58059b7d1eb7c94fbd', {
            cluster: 'eu',
        });

        const channel = pusher.subscribe('Command-channel' + userData.hotel.id);
        channel.bind('Command-event' + userData.hotel.id, (data) => {
            handleOrderResponse();
        });
        return () => {
            channel.unbind('Command-event' + userData.hotel.id);
            pusher.unsubscribe('Command-channel' + userData.hotel.id);
        };

    }, [])


    const getProductOptions = (options) => {
        const names = options?.reduce(function (s, a) {
            s.push(a?.option?.name);
            return s;
        }, []);
        return names?.toString()
    }

    const SubmitOrder = (e, id) => {

        e.preventDefault();

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        axios.put(`/commands/${id}`, { status: "delivered" }, config)
            .then((res) => {
                handleOrderResponse();
            })
            .catch((err) => {
                console.log(err);
            });
    };


    if (loading) {
        return <Loading />
    }

    return (
        <>
            <section id="Orders">
                <div className="header">
                    <div className="title">
                        <p>F&B ORDERS</p>
                    </div>
                </div>
                <div className="row order">
                    {
                        data.map(item => {
                            return item.status === "In preparation" ?
                                <div className="col-md-12" key={item.id}>
                                    <div className="orderItem" data-bs-toggle="collapse" data-bs-target={`#collapseOrder${item.id}`} aria-expanded="false" aria-controls={`collapseOrder${item.id}`}>
                                        <div className="order-content">
                                            <p className="order-item-title">Room Service #{item.room?.room_number ? item.room?.room_number : 0}</p>
                                            <p className="order-description">{item.command_articles?.map((des) => {
                                                return des.article?.description + "..."
                                            })}</p>
                                        </div>
                                        <div className="order-details">
                                            <div className="order-details-item">
                                                <p className="order-price">{item.total} MAD</p>
                                                <button className="order-details-btn btn" data-bs-toggle="collapse" data-bs-target={`#collapseOrder${item.id}`} aria-expanded="false" aria-controls={`collapseOrder${item.id}`}>
                                                    Details
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="more-order-details col-md-12">
                                        <div className="collapse collapse-order" id={`collapseOrder${item.id}`}>
                                            {
                                                item.command_articles?.map((el) => {
                                                    return (
                                                        <div className="order-product" key={el.id}>
                                                            <div className="order-product-detail">
                                                                <p className="order-product-name"> {el.article?.name}</p>
                                                                <p className="order-product-price"> {el.article?.price} Dhs</p>
                                                            </div>
                                                            <div className="order-product-quantity">
                                                                <p className="order-product-quantity-label">Quantity</p>
                                                                <p className="order-product-quantity-value">{el.quantity}</p>
                                                            </div>
                                                            {el.command_options?.length > 0 ?
                                                                <div className="order-product-option">
                                                                    <p className="order-product-option-label">Options</p>
                                                                    <p className="order-product-option-name">{getProductOptions(el.command_options)}</p>
                                                                </div> : ''}
                                                            {el.comment ? <div className="order-product-note">
                                                                <p className="order-product-note-label">Note</p>
                                                                <p className="order-product-note-name">{el.comment}</p>
                                                            </div> : ''}
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="order-bottom">
                                                <div className="order-product-time">
                                                    <p className="order-product-time-title">ORDER TIME</p>
                                                    <p className="order-product-time-month">{moment(item.created_at).format("DD-MM-YYYY")}</p>
                                                    <p className="order-product-time-hours">{moment(item.created_at).format("HH:mm A")}</p>
                                                </div>
                                                <div className="order-product-total">
                                                    <p className="order-product-total-title">TOTAL</p>
                                                    <p className="order-product-total-price">{item.total} MAD</p>
                                                </div>
                                            </div>
                                            <div className="order-submition">
                                                {item.userConversations[0] ?
                                                    <ProtectedButtons
                                                    allowedRoles={['receptionist', 'rooms-servant', 'housekeeping']}
                                                        element={<Link to={`/apps/messages/conversation/${item.userConversations[0]?.id}/${item.userConversations[0]?.room?.room_number}/${item.userConversations[0]?.room?.id}/${item.user?.id}`} className="details-btn btn">
                                                            <BsChat size={40} className="icons" />
                                                        </Link>}
                                                    /> : ''
                                                }
                                                <button className="details-btn btn" onClick={(e) => SubmitOrder(e, item.id)}>
                                                    <FaConciergeBell size={40} className="icons" />
                                                </button>
                                                <button className="details-btn btn" data-bs-toggle="collapse" data-bs-target={`#collapseOrder${item.id}`} aria-expanded="false" aria-controls={`collapseOrder${item.id}`}>
                                                    <FaArrowUpWideShort size={40} className="icons" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                        })
                    }
                </div>
            </section>
        </>
    )
}

export default Orders;