import { useEffect, useState } from "react";
import "./requests.scss";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FaConciergeBell } from "react-icons/fa";
import Loading from "../../Components/Loading/Loading";
import { BsChat } from 'react-icons/bs'
import pusherJs from "pusher-js";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ProtectedButtons from "../../Routes/Private/ProtectedButtons";

const Requests = () => {

    const [value, setValue] = useState(0);
    const [loading, SetLoading] = useState(true);
    const [data, setData] = useState([]);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const audio = new Audio('https://api.hotellom.com/audio/bell.mp3');

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    const handleRequestRespone = () => {
        axios
            .get(`demmandUsers`)
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {

        axios.get(`demmandUsers`).then(response => {
            if (loading) {
                setData(response.data.data);
            }
            SetLoading(false);
        }).catch(error => console.log(error))


        const pusher = new pusherJs('ca58059b7d1eb7c94fbd', {
            cluster: 'eu',
        });
        const channel = pusher.subscribe('demmandUsersNotif-channel' + userData.hotel.id);
        channel.bind('demmandUsersNotif-event' + userData.hotel.id, (data) => {
            handleRequestRespone();
        });
        return () => {
            channel.unbind('demmandUsersNotif-event' + userData.hotel.id);
            pusher.unsubscribe('demmandUsersNotif-channel' + userData.hotel.id);
        };

    }, [])

    const SubmitRequest = (e, id) => {

        e.preventDefault();

        const formdata = new FormData();
        formdata.append("done_by", id);
        formdata.append("status", "done");

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        axios.put(`/demmandUsers/${id}`, formdata, config)
            .then((res) => {
                toast.success("the request Submitted successfully");
                handleRequestRespone();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <section id="Requests">
                <div className="header">
                    <div className="title">
                        <p>Requests</p>
                    </div>
                    <Tabs value={value} onChange={handleChange} centered className="tabs" indicatorColor="none" >
                        <Tab label="All" className="tab" />
                        <Tab label="Done" className="tab" />
                    </Tabs>
                </div>
                <div>
                    {value === 0 &&
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    data.map(item => {
                                        return item.status === "pending" ?
                                            <div className="item pending" key={item.id}>
                                                <div className="logo">
                                                    <div className="image" style={{ backgroundImage: `url(${item.demmand.icon})` }} />

                                                </div>
                                                <div className="content">
                                                    <p className="request">{item.demmand.name}</p>
                                                    <p className="type-request">{item.demmand.name} for room #{item.room?.room_number}</p>
                                                    <p className="message-request">
                                                        <span>{item.option?.name} {item.option?.name ? <br /> : ''}</span>
                                                        {item.message.substring(item.message.indexOf('\n') + 1)}
                                                    </p>
                                                </div>
                                                <div className="details">
                                                    <ProtectedButtons
                                                        allowedRoles={['admin', 'receptionist','rooms-servant', 'manager', 'housekeeping']}
                                                        element={<Link to={`/apps/messages/conversation/${item?.conversation?.id}/${item?.room?.room_number}/${item?.room?.id}/${item?.user?.id}`} className="message-btn btn">
                                                            <BsChat size={40} className="icons" />
                                                        </Link>}
                                                    />
                                                    <button className="confirm-btn btn" onClick={(e) => SubmitRequest(e, item.id)}>
                                                        <FaConciergeBell size={40} className="icons" />
                                                    </button>
                                                </div>
                                            </div> : ''
                                    })
                                }
                            </div>
                        </div>
                    }
                    {value === 1 &&
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    data.map(item => {
                                        return item.status === "done" ? <div className="item done" key={item.id}>
                                            <div className="logo">
                                                <div className="image" style={{ backgroundImage: `url(${item.demmand.icon})` }} />

                                            </div>
                                            <div className="content">
                                                <p className="request">{item.demmand.name}</p>
                                                <p className="type-request">{item.demmand.name} for room #{item.room?.room_number}</p>
                                                <p className="message-request">
                                                    <span>{item.option?.name} {item.option?.name ? <br /> : ''}</span>
                                                    {item.message.substring(item.message.indexOf('\n') + 1)}
                                                </p>
                                                <p className="done-by">DONE BY <span>{item.done_by?.name}</span></p>
                                            </div>
                                            <div className="details">
                                                <ProtectedButtons
                                                    allowedRoles={['admin', 'receptionist','rooms-servant', 'manager', 'housekeeping']}
                                                    element={<Link to={`/apps/messages/conversation/${item?.conversation?.id}/${item?.room?.room_number}/${item?.room?.id}/${item?.user?.id}`} className="message-btn btn">
                                                        <BsChat size={40} className="icons" />
                                                    </Link>}
                                                />
                                            </div>
                                        </div> : ''
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            </section >
        </>
    )
}

export default Requests;