import { useNavigate } from "react-router-dom";
import { getUserData } from "../../../../Services/utils";
import Button from "../../../Components/Button/Button";
import "./editprofile.scss";
import { useState } from "react";
import axios from "axios";

const EditProfile = () => {

    const history = useNavigate()
    const userData = getUserData();

    const [params, setparams] = useState({
        firstname: userData.firstname,
        lastname: userData.lastname,
        email: userData.email
    });

    const handleInput = (e) => {
        setparams({ ...params, [e.target.name]: e.target.value })
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const formData = new FormData();
        formData.append("firstname", params.firstname);
        formData.append("lastname", params.lastname);
        formData.append("email", params.email);


        axios.post(`users/edit/${userData.id}`, formData, config)
            .then((res) => {
                localStorage.setItem('userData', JSON.stringify(res.data));
                history('/apps/profile');
            })
            .catch((err) => {
                console.log(err);
            });
    };


    return (
        <section className="edit-profile" >
            <div className="container" >
                <form onSubmit={handleSubmit}>
                    <h3 className="text-center" >Edit Profile</h3>
                    <div className="mb-3">
                        <label className="form-label">First name</label>
                        <input
                            value={params.firstname}
                            onChange={handleInput}
                            required
                            name='firstname'
                            type="text"
                            className="form-control"
                            placeholder="" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Last name</label>
                        <input
                            value={params.lastname}
                            onChange={handleInput}
                            required
                            name='lastname'
                            type="text"
                            className="form-control"
                            placeholder="" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email address</label>
                        <input

                            value={params.email}
                            onChange={handleInput}
                            required
                            name='email'
                            type="email"
                            className="form-control"
                            placeholder="" />
                    </div>
                    <Button type='submit' className='btn primary w-100 mb-3 mt-4' value={'Edit'} />
                </form>
            </div>
        </section>
    );
}

export default EditProfile;