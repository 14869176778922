import { useEffect, useState } from "react";
import "./deals.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import DealsModal from "../../Components/DealsModal/DealsModal";
import Pusher from "pusher-js";
import { toast } from "react-toastify";

const Deals = () => {
  const [value, setValue] = useState(0);
  const [loading, SetLoading] = useState(true);
  const [data, setData] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const audio = new Audio("https://api.hotellom.com/audio/bell.mp3");

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleOfferResponse = () => {
    axios
      .get(`commandOffers`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    axios
      .get(`commandOffers`)
      .then((response) => {
        if (loading) {
          setData(response.data);
        }
        SetLoading(false);
      })
      .catch((error) => console.log(error));

    const pusher = new Pusher("ca58059b7d1eb7c94fbd", {
      cluster: "eu",
    });
    const channel = pusher.subscribe(
      "CommandOffer-channel" + userData.hotel.id
    );
    channel.bind("CommandOffer-event" + userData.hotel.id, (data) => {
      handleOfferResponse();
    });
    return () => {
      channel.unbind("CommandOffer-event" + userData.hotel.id);
      pusher.unsubscribe("CommandOffer-channel" + userData.hotel.id);
    };
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <section id="Deals">
        <div className="header">
          <div className="title">
            <p>DEALS ORDERS</p>
          </div>
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            className="tabs"
            indicatorColor="none"
          >
            <Tab label="All" className="tab" />
            <Tab label="Done" className="tab" />
          </Tabs>
        </div>

        <div>
          {value === 0 && (
            <div className="row order">
              {data.map((item) => {
                return item.orderStatus === "inPreparation" ? (
                  <div className="col-md-6" key={item.id}>
                    <div
                      className="item"
                      data-bs-toggle="modal"
                      data-bs-target={`#E${item.id}`}
                    >
                      <div
                        className={`image ${
                          item.offer?.image ? "" : "default-image"
                        }`}
                        style={{
                          backgroundImage: `url(${item.offer.image})`,
                          backgroundSize: "cover",
                        }}
                      />
                      <div className="content">
                        <p className="item-title">{item.offer.titre}</p>
                        <label className="room">
                          RS Order #{item.id} Room #
                          {item.room?.room_number ? item.room?.room_number : 0}
                        </label>
                        <p className="description">{item.offer.description}</p>
                      </div>
                      <div className="details">
                        <p className="old-price">{item.offer.prix} MAD</p>
                        <label className="price">
                          {item.offer.prixFinal} MAD
                        </label>
                        <button
                          className="details-btn btn"
                          data-bs-toggle="modal"
                          data-bs-target={`#E${item.id}`}
                        >
                          Details
                        </button>
                      </div>
                    </div>
                    <DealsModal
                      item={item}
                      onOfferSubmit={handleOfferResponse}
                    />
                  </div>
                ) : (
                  ""
                );
              })}
            </div>
          )}
          {value === 1 && (
            <div className="row order">
              {data.map((item) => {
                return item.orderStatus === "delivered" ? (
                  <div className="col-md-6" key={item.id}>
                    <div
                      className="item"
                      data-bs-toggle="modal"
                      data-bs-target={`#E${item.id}`}
                    >
                      <div
                        className={`image ${
                          item.offer.image ? "" : "default-image"
                        }`}
                        style={{
                          backgroundImage: `url(${
                            item.offer.image ? item.offer.image : "none"
                          })`,
                        }}
                      />
                      <div className="content">
                        <p className="item-title">{item.offer.titre}</p>
                        <label className="room">RS Order #27 Room #107</label>
                        <p className="description">
                          Learn To surf with our professional Learn To surf with
                          our professiona lLearn To surf with our professional
                        </p>
                      </div>
                      <div className="details">
                        <p className="old-price">350 MAD</p>
                        <label className="price">210 MAD</label>
                        <button
                          className="details-btn btn"
                          data-bs-toggle="modal"
                          data-bs-target={`#E${item.id}`}
                        >
                          Details
                        </button>
                      </div>
                    </div>
                    <DealsModal item={item} />
                  </div>
                ) : (
                  ""
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Deals;
